import { template as template_1e3fb6c28b494974a487445907282c71 } from "@ember/template-compiler";
const WelcomeHeader = template_1e3fb6c28b494974a487445907282c71(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
