import { template as template_03cee85eaf7d482785c47e04d3486c46 } from "@ember/template-compiler";
const FKLabel = template_03cee85eaf7d482785c47e04d3486c46(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
